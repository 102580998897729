new WOW().init();

// Responses without this status will trigger error conditions
$.fn.api.settings.successTest = function(response) {
    return response.status == 'success';
};

/* One required, one optional variable */
$.fn.api.settings.api = {
    'like dataset' : '/data-sets/{id}/like'
};

$.fn.api.settings.beforeXHR = function(xhr) {
    xhr.setRequestHeader('X-CSRF-TOKEN', Glinse.csrfToken);
}

/**
 * Set csrf token on all http requests.
 */
Vue.http.interceptors.push(function (request, next) {
    request.headers.set('X-CSRF-TOKEN', Glinse.csrfToken);
    next();
});

Lang.setLocale(appLocale);

/**
 * Datatables
 */
var datatablesSettingsObject = function () {
    return {
        "order": [[0, "desc"]],
        "language": {
            "url": baseUrl + "/build/vendor/datatables/i18n/French.json"
        }
    };
};

/**
 * Semantic ui
 */
$('.ui.auto-open.accordion').accordion().accordion('open', 0);
$('.ui.dropdown:not(.skip_app_init)').dropdown({
    fullTextSearch: true,
    placeholder: false,
    forceSelection: false
});

$('.ui.checkbox:not(.skip_app_init)').checkbox();

$('.tabular.menu .item').tab();

$('.ui.popup:not(.skip_app_init)').popup();

/**
 * Clipboard
 */
var clipboard = new Clipboard('.CopyButton');

$(document).ready(function () {
    $('.CopyButton').popup({
        on: 'manual'
    });
});

clipboard.on('success', function(e) {
    $('.CopyButton').popup('show');

    setTimeout(function () {
        $('.CopyButton').popup('hide');
    }, 1000);

    e.clearSelection();
});

/**
 * Notifications
 */

function notifyWeb(settings, type, pluginSettings) {
    var theme, icon, options;

    switch (type) {
        case 'info':
            theme = 'awesome blue';
            icon  = 'fa fa-info';
            break;

        case 'success':
            theme = 'awesome green';
            icon  = 'fa fa-check';
            break;

        case 'error':
            theme = 'awesome error';
            icon  = 'fa fa-times';
            break;

        case 'warning':
            theme = 'awesome yellow';
            icon  = 'fa fa-exclamation-triangle';
            break;

        default:
            theme = 'awesome blue';
            icon  = 'fa fa-info';
            break;
    }

    options = {
        'theme': theme,
        'content': {
            title  : settings.title,
            message: settings.message,
            info   : _.has(settings, "extra") ? settings.extra : "",
            icon   : icon
        },
        'position': _.has(settings, "position") ? settings.position : 'top right',
        'cssanimationIn': _.has(settings, "animationIn") ? settings.animationIn : 'slideInDown',
        'cssanimationOut': _.has(settings, "animationOut") ? settings.animationOut : 'slideOutRight',
        onClick: _.has(settings, "onClick") ? settings.onClick : function () {}
    };

    if( pluginSettings )
    {
        options = _.extend(options, pluginSettings);
    }

    return $.amaran(options);
}

function notifyDesktop(settings, type, pluginSettings) {
    Push.create(settings.title, {
        body: settings.message,
        icon: baseUrl + "/build/images/notifications/desktop_notification_logo.png",
        timeout: 10000,
        onClick: function () {
            window.focus();

            if( _.has(settings, "onClick") ) {
                settings.onClick();
            }

            this.close();
        },
        tag: pluginSettings.tag
    });
}

function notify(settings, type, pluginSettings) {
    /*var desktopNotificationPermission = Push.Permission.get();

    if( Push.isSupported && desktopNotificationPermission != Push.Permission.DENIED )
    {

    }*/

    notifyWeb(settings, type, pluginSettings);

    if( settings.desktopNotifications )
    {
        notifyDesktop(settings, type, pluginSettings);
    }
}